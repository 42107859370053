@import "./colors";

.single-select-container {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;

  &.new-style {

    label {
      position: absolute;
      left: 14px;
      font-size: 13px;
      margin: 0;
      color: $gray60;
      top: 2px;
      z-index: 1;
    }

    .r-ss-expanded {
      background: transparent;
      z-index: unset;
    }

    .r-ss-trigger {
      border-radius: 0.25rem 0.25rem 0 0;
      border-bottom-width: 2px;
      border-color: $gray15;
      padding: 0.95rem 30px 0 0.85rem;

      .r-ss-selected-label {
        font-size: 15px;
        color: $black;
        //set height in case value of dropdown has not label yet. e.g. value is initialized but datasource is still loading.
        //this prevents blinking of dropdown
        height: 30px;
      }

      .r-ss-selection-clear {
        top: 14px;
        right: 38px;
        background-color: initial;
        border: none;
        &:focus{
          outline: none;
        }
        :before {
        font-weight: 400;
        font-style: normal;
        font-size: 20px;
        line-height: 1;
        content: url("./assets/cancel-clear-button.svg");
        width: 80px;
        pointer-events: none;
        left: -30px;
        top: 0;
        color: initial;
        }
      }

      .carat.up {
        top: 14px;
        border: 5px solid;
        border-color: transparent transparent $gray85 transparent;
        margin-right: 14px;
      }

      .carat.down {
        top: 22px;
        border: 5px solid;
        border-color: $gray85 transparent transparent transparent;
        margin-right: 14px;
      }
    }

    .r-ss-open{
      border-bottom-color: $purple75;
      background-color: $purple20;
    }

    .r-ss-dropdown {
      top: 110%;
      border: none;
      border-radius: 4px 4px 0 0;
      box-shadow: 0 2px 9px $aero80, 0 1px 3px $aero80;
      z-index: 2;

      .r-ss-search-wrap {
        padding: 0px;
        box-sizing: border-box;
        background-color: #f6ecf1;
        border-bottom: 1px solid $gray15;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;

        .r-ss-search-inner {
          height: 56px;
          position: relative;
          width: 100%;
          margin: 0;
          padding: 0;
          display: block;
          color: $purple80;
          font-size: 14px;

          .r-ss-search-clear {
            top: 5px;
            right: 17px;
            background-color: initial;
            border: none;
            :before{
              font-weight: 400;
              font-style: normal;
              font-size: 20px;
              line-height: 1;
              content: url("./assets/cancel-clear-button.svg");
              width: 80px;
              pointer-events: none;
              left: -30px;
              top: 0;
              color: initial;
            }
          }

          .r-ss-magnifier{
            position: absolute;
            top: 20px;
            right: .5rem;
            text-indent: -9999px;
            display: inline-block;
            width: 16px;
            height: 16px;
            cursor: pointer;
          }

          input {
            height: 48px;
            padding-top: 32px;
            padding-right: 60px;
            padding-bottom: 0px;
            padding-left: 16px;
            line-height: 20px;
            border: none;
            background: transparent;
          }
          .r-ss-search-aria-label{
            display: block;
            padding-left: 0px;
            padding-top: 5px;
            margin: 2px;
            color: $purple80;
            font-size: 12px;
          }
        }
      }

      .r-ss-options-wrap {
        max-height: 267px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        .r-ss-dropdown-options {
          position: relative;
          max-height: none;
          width: 100%;
          overflow-y: auto;
          .r-ss-dropdown-option {
            padding: 14px;
            border: none;

            &.r-ss-selected {
              background-color: $gray5;
              color: $purple80;
              font-weight: 700;
            }

            i {
              font-size: 14px;
            }
          }

        }
      }
    }
  }
}
