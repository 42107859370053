
.single-select-container.multiple-select-container {
    li[role="separator"]{
      display: block;
      font-size: 0 !important;
      height: 1px;
      padding: 0;
      border-top: none;
      border-bottom-color: #DBDBDB !important;
    }
    .r-ss-wrap {
      .r-ss-trigger {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        min-width: 200px;
        padding-right: 92px !important;
      }
      .placeholder {
        color: #323232;
      }

      .r-ss-dropdown {
        .r-ss-dropdown-options {
          max-height: 205px;
          .r-ss-option-group-heading {
            margin-top: 0;
            &:first-child {
              display: none;
            }
          }
          .r-ss-dropdown-option {
            display: flex;
            > i {
              &.checkbox-icon {
                border-radius: 3px;
                width: 18px;
                height: 18px;
                display: block;
                border: 1px solid #691E44;
                position: relative;
                + span {
                  padding-left: 7px;
                  line-height: 18px;
                }

                &:before {
                  position: absolute;
                  left: 6px;
                  top: 2px;
                  width: 5px;
                  height: 10px;
                  border: solid #ffffff;
                  border-width: 0 2px 2px 0;
                  transform: rotate(45deg);
                  display: block;
                }
                &.checkbox-icon-indeterminate, &.checkbox-icon-checked {

                }
                &.checkbox-icon-indeterminate {
                  background-color: #691E44;
                  &:before {
                    content: '';
                    transform: none;
                    width: 8px;
                    height: 0;
                    top: 7px;
                    left: 4px;
                  }
                }
                &.checkbox-icon-checked {
                  background-color: #691E44;
                  &:before {
                    content: ' ';
                  }
                }
              }
            }

            &:focus {
              border-left: none;
              border-right: none;
              border-top: none;
              background-color: #f6ecf1;
            }

            &:hover {
              background-color: #F3F3F3 !important;
            }

            &:before {
              display: none !important;
            }

            &.r-ss-selected {
              background: none;
              font-weight: normal;
              border: none;
              &:focus {
                background-color: #f6ecf1;
              }
              > i {
                &.checkbox-icon {
                  background-color: #691E44;
                  &:before {
                    content: ' ';
                  }
                }
              }
            }
          }
        }
      }

      .count {
        background-color: #691E44;
        color: #ffffff;
        padding: 6px;
        display: inline-block;
        float: left;
        border-radius: 20px;
        line-height: 8px;
        position: absolute;
        right: 56px;
        top: 14px;
        min-width: 20px;
      }
    }
  }
