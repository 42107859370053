.custom-dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: initial;
    border: 0;
}

.custom-dropdown-item:active, .custom-dropdown-item .active {
    background-color: #691e44 !important;
}

.custom-dropdown-item:hover, .custom-dropdown-item .hover {
    background-color: #54092f !important;
}