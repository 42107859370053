body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.9rem!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-p {
  background-color: #691e44;
}

.c-p {
  color: #7d3559;
}

.c-w {
  color: #6395d6;
}

.footer-bg-light {
  background-color: rgba(105,30,38,0.2);
}

.border-bottom-p {
  border-bottom: 1px solid #6395d6;
}

.modal-footer {
  border: none;
}

.form-control {
  border-radius: 0;
  font-size: .9rem;
}

.form-control:not(textarea) {
  padding: .3rem .75rem;
  height: calc(2.0rem + 2px);
}

.custom-checkbox {
  height: 20px !important;
  width: 20px;
}

.form-control[readonly] {
  background-color: #fff;
}

.form-control[disabled] {
  background-color: #f4f4f4 !important;
  cursor: not-allowed !important;
  opacity: 1;
}

.req-field {
  border-left: 3px solid #f52433;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

*::-webkit-input-placeholder {
  color: #d8d8d8!important;
}
*:-moz-placeholder {
  /* FF 4-18 */
  color: #d8d8d8!important;
}
*::-moz-placeholder {
  /* FF 19+ */
  color: #d8d8d8!important;
}
*:-ms-input-placeholder {
  /* IE 10+ */
  color: #d8d8d8!important;
}

/*------------React Table Custom Start ---------*/
.ReactTable .rt-th, .ReactTable .rt-td {
  padding: 7px 8px;
}
.ReactTable .rt-thead.-header {
  box-shadow: none;
  border-bottom: 1px solid #7d3559;
  font-weight: 600;
  color:#7d3559;
}
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  padding: 8px 8px;
}
.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgb(105, 30, 68);
}
.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgb(105, 30, 68);
}
.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgb(222, 226, 230);
}
.ReactTable  .rt-th{
  text-align: left;
}
.ReactTable .-pagination .-pageJump input {
  -moz-appearance: textfield;
}
/*------------React Table Custom End ---------*/
.cursor-pointer{
  cursor : pointer
}
.ReactTable .nav-link {
  display: block;
  padding: 0 0.1rem;
}
.ReactTable .rt-tbody .rt-tr .activelink:hover {
  cursor: pointer;
}
.ReactTable  .rt-tbody .rt-tr:focus{
  background-color: #f3edf0 !important;
}
.ReactTable  .rt-tbody .rt-tr:active{
  background-color: #f3edf0 !important;
}

/*---------Pagination CSS ------------*/
.ReactTable .pagination-bottom {
  border-top: 1px solid #f5f5f5;
}
.ReactTable .-pagination {
  float: right;
  font-size: 11px;
  border-top:0;
  box-shadow: none;
}
.ReactTable .-btn {
  width: auto !important;
}
.ReactTable .-pagination .-center {
  -webkit-box-flex: unset;
  -ms-flex: unset;
  -webkit-flex: unset;
  flex: unset;
  text-align: center;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: unset;
  -webkit-box-orient: unset;
  -webkit-box-direction: unset;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.ReactTable .-pagination .-previous, .ReactTable .-pagination .-next {
  -webkit-box-flex: unset;
  -ms-flex: unset;
  -webkit-flex: unset;
  flex: unset;
  text-align: center;
}
.plus-sign{
  position: absolute;
  top: -2px;
  left: 5px;
}

.version{
  text-align: center;
  color: #6E6E6E;
  font-size: 10px;
  line-height: 16px;
  padding: 25px 0 5px;
}