.radio-button-section {
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.add-group-section {
    align-items: flex-end;
    .btn {
        line-height: 20px;
        width: 100%;
    }
}
