.overlay{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1111;
    background-color: rgba(0, 0, 0, .65);
}

.content{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    overflow: auto;
    text-align: center;
    -webkit-overflow-scrolling : touch;
    padding: 4px;
    cursor: pointer;
}

.content:after {
    vertical-align: middle;
    display: inline-block;
    height: 100%;
    margin-left: -.05em;
    content: '';
}

.dialog{
    position: relative;
    outline: 0;
    width: 100%;
    background: white;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    max-width: 650px;
    cursor: default;
    z-index: 1111;
}

.header{
    padding: 16px 8px 8px 8px
}

.body{
    padding-bottom: 16px
}
