.user-role-form-template {
    .user-role-form-template-row {
        display: flex;
        .role-section{
            flex: 0 0 260px;
            label{
                margin-left: 5px;
            }
            h5{
                margin-bottom: 1rem;
            }
        }
        .role-section-right {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .study-section, .site-section, .site-multi-section, .pcn-section, .finance-details-access-section, .blinding-groups-section{
                margin-left: 20px;
                margin-top: 20px;
                display: inline-block;
                width: 250px;
            }
            .blinding-groups-section{
                label{
                    margin-left: 5px;
                }
            }
        }
    }
    .divider{
        margin: 15px 0 15px 0;
        border-bottom: 1px solid lightgrey;
    }
}
